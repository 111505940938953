
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { Debounce } from "vue-debounce-decorator";
@Component
export default class Telefonos extends Vue {
  private unidades: any = null;
  private unidadesMenu: any = null;
  private busqueda: any = null;
  private text: string = "";
  private selected: any = null;
  private verContactos: boolean = false;

  @Watch("text")
  private watchText(newValue: any) {
    this.buscar();
  }
  private mounted() {
    this.getTelefonos();
  }

  private getTelefonos() {
    this.$axios
      .get("telefonos")
      .then(res => {
        this.unidades = res.data;
        this.busqueda = res.data;
        this.unidadesMenu = [];
        for (let i = 0; i < res.data.length; i++) {
          const element = res.data[i];
          this.unidadesMenu.push({
            nombre: element.nombre,
            id: element.id
          });
        }
        this.selected = this.busqueda[0];
      })
      .catch((err: any) => {
        this.routerGo("Home");
        this.unidades = null;
      });
  }

  private getStyle(id: any) {
    if (id == this.selected.id) {
      return "border-bottom: 1px solid grey;background-color:#f1f1f1;";
    }
    return "border-bottom: 1px solid grey;";
  }

  @Debounce(500)
  private buscar() {
    if (this.text.trim().length > 1) {
      let result: any = [];
      for (let i = 0; i < this.unidades.length; i++) {
        let unidad = this.unidades[i];
        let contiene = false;
        if (unidad.nombre.toUpperCase().indexOf(this.text.toUpperCase()) >= 0) {
          contiene = true;
        } else if (
          unidad.direccion &&
          unidad.direccion.toUpperCase().indexOf(this.text.toUpperCase()) >= 0
        ) {
          contiene = true;
        } else if (
          unidad.horario &&
          unidad.horario.toUpperCase().indexOf(this.text.toUpperCase()) >= 0
        ) {
          contiene = true;
        } else if (
          unidad.responsable &&
          unidad.responsable.toUpperCase().indexOf(this.text.toUpperCase()) >= 0
        ) {
          contiene = true;
        } else {
          let contactosFiltrados: any = [];
          for (let j = 0; j < unidad.contacto.length; j++) {
            const contacto = unidad.contacto[j];
            if (
              contacto.nombre &&
              contacto.nombre.toUpperCase().indexOf(this.text.toUpperCase()) >=
                0
            ) {
              contactosFiltrados.push(contacto);
              contiene = true;
              continue;
            } else if (
              contacto.email &&
              contacto.email.toUpperCase().indexOf(this.text.toUpperCase()) >= 0
            ) {
              contactosFiltrados.push(contacto);
              contiene = true;
              continue;
            }
          }
          if (contactosFiltrados.length > 0) {
            unidad.contacto = contactosFiltrados;
          } else {
            unidad.contacto = this.unidades[i].contacto;
          }
        }
        if (contiene) {
          result.push(unidad);
        }
      }
      if (result.length > 0) {
        this.busqueda = result;
        this.selected = this.busqueda[0];
      } else {
        this.busqueda = null;
      }
    } else if (this.text.trim() == "") {
      this.getTelefonos();
    }
  }

  private mostrarDatos(id: any) {
    for (let i = 0; i < this.busqueda.length; i++) {
      const element = this.busqueda[i];
      if (element.id == id) {
        this.selected = element;
        break;
      }
    }
    if (this.$q.screen.xs || this.$q.screen.sm) {
      this.verContactos = true;
    }
  }

  private routerGo(name: string) {
    if (name && name != "") {
      if (this.$router.currentRoute.name == name) {
        this.$router.go(0);
      } else {
        this.$router.push({ name: name }).catch(err => {
          console.log(err);
        });
      }
    }
  }
}
